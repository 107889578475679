import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import comuniJson from "../../Json/gi_comuni.json";
import { storage } from '../Firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage"; // Add listAll

function AddPostModal({ show, handleClose, editPost, onPostUpdated }) {
    const [type, setType] = useState('');
    const [province, setProvince] = useState('');
    const [municipality, setMunicipality] = useState('');
    const [status, setStatus] = useState('');
    const [price, setPrice] = useState('');
    const [mt2, setMt2] = useState('');
    const [description, setDescription] = useState('');
    const [pubblic, setPubblic] = useState(false);
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);
    const [imageUrls, setImageUrls] = useState([]); // To store uploaded image URLs

    useEffect(() => {
        // Reset state when modal opens
        setType('');
        setProvince('');
        setMunicipality('');
        setStatus('');
        setPrice('');
        setMt2('');
        setDescription('');
        setPubblic(false);
        setImages([]);
        setImageUrls([]);
    }, [show]);

    const provinces = [...new Set(comuniJson.map(comune => comune.sigla_provincia))];

    const handleProvinceChange = (e) => {
        const selectedProvince = e.target.value;
        setProvince(selectedProvince);

        const filtered = comuniJson
            .filter(comune => comune.sigla_provincia === selectedProvince)
            .map(comune => comune.denominazione_ita);
        setFilteredMunicipalities(filtered);
        setMunicipality('');
    };

    const generateUniqueID = () => {
        // Generate a unique 20-digit number
        return String(Math.floor(Math.random() * 1e20)).padStart(20, '0');
    };

    const checkFileExists = async (uniqueId) => {
        const listRef = ref(storage, 'images/');
        const res = await listAll(listRef);
        return res.items.some(item => item.name === uniqueId);
    };

    const getUniqueID = async () => {
        let uniqueId;
        let exists;

        do {
            uniqueId = generateUniqueID();
            exists = await checkFileExists(uniqueId);
        } while (exists); // Repeat until a unique ID is found

        return uniqueId;
    };

    const handleFileChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        setUploading(true);
        const uploadedUrls = await uploadImages(selectedFiles);
        setImageUrls(prevUrls => [...prevUrls, ...uploadedUrls]); // Add uploaded URLs to imageUrls
        setUploading(false);
    };

    const uploadImages = async (images) => {
        const urls = [];
        for (const image of images) {
            const uniqueId = await getUniqueID(); // Get a unique ID
            const imageRef = ref(storage, `images/${uniqueId}`); // Use unique ID as image name
            await uploadBytes(imageRef, image);
            const url = await getDownloadURL(imageRef);
            urls.push({ url, imageRef }); // Save the imageRef along with the URL for future deletion
        }
        return urls;
    };

    const handleRemoveImage = async (imageObj) => {
        const { url, imageRef } = imageObj;

        // Remove the image from Firebase Storage
        try {
            await deleteObject(imageRef);
            setImageUrls(prevUrls => prevUrls.filter(img => img.url !== url)); // Remove image from the list
        } catch (error) {
            console.error('Error deleting image from storage:', error);
        }
    };

    const handleSubmit = async () => {
        const postData = {
            type,
            province,
            municipality,
            status,
            price,
            mt2,
            description,
            pubblic,
            imageURLs: imageUrls.map(img => img.url),
        };

        try {
            const response = await fetch('https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/save_new_post', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            if (response.ok) {
                console.log('Post created successfully!');
                handleCloseModal();
                onPostUpdated(); 
            } else {
                console.error('Error saving the post:', await response.text());
            }
        } catch (error) {
            console.error('Error occurred while saving the post:', error);
        }
    };

    const handleCloseModal = () => {
        setType('');
        setProvince('');
        setMunicipality('');
        setStatus('');
        setPrice('');
        setMt2('');
        setDescription('');
        setPubblic(false);
        setImages([]);
        setImageUrls([]);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{editPost ? 'Modifica Annuncio' : 'Nuovo Annuncio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="input_card_modale">
                    <p className="filter_labels">Tipologia</p>
                    <select className="filter_input" value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="">-</option>
                        <option value="Appartamento">Appartamento</option>
                        <option value="Attività commerciale">Attività commerciale</option>
                        <option value="Bifamiliare">Bifamiliare</option>
                        <option value="Casa Singola">Casa singola</option>
                        <option value="Garage">Garage</option>
                        <option value="Magazzino">Magazzino</option>
                        <option value="Negozio">Negozio</option>
                        <option value="Posto auto">Posto auto</option>
                        <option value="Rustico">Rustico</option>
                        <option value="Terreno">Terreno</option>
                        <option value="Ufficio">Ufficio</option>
                        <option value="Villa">Villa</option>
                    </select>

                    <p className="filter_labels">Provincia</p>
                    <select className="filter_input" value={province} onChange={handleProvinceChange}>
                        <option value="">-</option>
                        {provinces.map((prov, idx) => (
                            <option key={idx} value={prov}>{prov}</option>
                        ))}
                    </select>

                    <p className="filter_labels">Comune</p>
                    <input
                        className="filter_input"
                        value={municipality}
                        onChange={(e) => setMunicipality(e.target.value)}
                    />

                    <p className="filter_labels">Vendita/Affitto</p>
                    <select className="filter_input" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">-</option>
                        <option value="Vendita">Vendita</option>
                        <option value="Affitto">Affitto</option>
                    </select>

                    <p className="filter_labels">Prezzo</p>
                    <input
                        type="number"
                        className="filter_input"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />

                    <p className="filter_labels">Metri quadri</p>
                    <input
                        type="number"
                        className="filter_input"
                        value={mt2}
                        onChange={(e) => setMt2(e.target.value)}
                    />

                    <p className="filter_labels">Descrizione</p>
                    <input
                        className="filter_input"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <p className="filter_labels">Immagini</p>
                    <input
                        type="file"
                        className="form-control filter_input_file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                    />

                    {imageUrls.length > 0 && (
                        <div className="existing-images">
                            <p>Immagini da caricare:</p>
                            <div className="img_cont_prev">
                                {imageUrls.map((img, index) => (
                                    <div className='img_prev' key={index}>
                                        <a href={img.url} target="_blank" rel="noopener noreferrer">
                                            <img src={img.url} alt={`Image ${index}`} style={{ width: '100px', marginRight: '10px' }} />
                                        </a>
                                        <button className='remove_img_btn' onClick={() => handleRemoveImage(img)}>X</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {uploading && <p>Caricamento...</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Chiudi
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {editPost ? 'Aggiorna' : 'Salva'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddPostModal;
