import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import AddPostModal from "../PostManager/AddPostModal";
import UpdatePostModal from "../PostManager/UpdatePostModal"; // Import the UpdatePostModal
import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import ContactsManager from "../ContactsDashboard/ContactsManages";
import FullImgModal from "../Immobili_in_vendita/FullImgModal";

function Dashboard() {
    const navigate = useNavigate();
    const [addModalShow, setAddModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false); // State for update modal visibility
    const [posts, setPosts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [activeSection, setActiveSection] = useState("posts");
    const [selectedPost, setSelectedPost] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    const handleShowAdd = () => setAddModalShow(true);
    const handleCloseAdd = () => setAddModalShow(false);

    const handleShowUpdate = () => setUpdateModalShow(true);
    const handleCloseUpdate = () => setUpdateModalShow(false);

    const AddNewPostClick = () => {
        setSelectedPost(null);
        handleShowAdd();
    };

    const fetchPosts = () => {
        fetch("https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/get_all_posts")
            .then((response) => response.json())
            .then((data) => {
                const postsArray = [];
                const initialImageIndex = {};

                data.forEach((post) => {
                    postsArray.push(post);
                    initialImageIndex[post._id] = 0; // Use _id here
                });

                setPosts(postsArray);
                setCurrentImageIndex(initialImageIndex);
            })
            .catch((error) => {
                console.error("Error fetching posts:", error);
            });
    };

    useEffect(() => {
        fetchPosts(); // Initial fetch
    }, []);

    useEffect(() => {
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        if (activeSection === "contacts") {
            fetch("/get_contacts")
                .then((response) => response.json())
                .then((data) => {
                    const contactsArray = data.map((contact) => ({
                        _id: contact._id, // Use _id for contacts as well
                        ...contact,
                    }));
                    setContacts(contactsArray);
                })
                .catch((error) => {
                    console.error("Error fetching contacts:", error);
                });
        }
    }, [activeSection]);

    const handleDelete = (postId, imageUrls = []) => {
        const storage = getStorage();

        // Check if there are images associated with the post
        if (Array.isArray(imageUrls) && imageUrls.length > 0) {
            // Create an array of promises for deleting images
            const deleteImagePromises = imageUrls.map((url) => {
                const imgRef = storageRef(storage, url);
                return deleteObject(imgRef)
                    .then(() => {
                        console.log(`Image deleted successfully: ${url}`);
                    })
                    .catch((error) => {
                        console.error(`Error deleting image: ${url}`, error);
                    });
            });

            // Once all image deletion promises resolve, delete the post
            Promise.all(deleteImagePromises)
                .then(() => {
                    console.log(
                        "All images deleted successfully, now deleting the post."
                    );
                    return fetch(`https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/delete_post/${postId}`, {
                        method: "DELETE",
                    });
                })
                .then(() => {
                    console.log("Post deleted successfully");
                    setPosts(posts.filter((post) => post._id !== postId)); // Use _id here
                })
                .catch((error) => {
                    console.error("Error deleting post:", error);
                });
        } else {
            // If no images, directly delete the post
            fetch(`https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/delete_post/${postId}`, { method: "DELETE" })
                .then(() => {
                    console.log("Post deleted successfully");
                    setPosts(posts.filter((post) => post._id !== postId)); // Use _id here
                })
                .catch((error) => {
                    console.error("Error deleting post:", error);
                });
        }
    };

    const handlePublish = (postId) => {
        fetch(`https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/publish_post/${postId}`, { method: "PUT" }) // Update with publish endpoint
            .then(() => {
                console.log("Post published successfully");
                setPosts(
                    posts.map(
                        (post) => (post._id === postId ? { ...post, pubblic: true } : post) // Use _id here
                    )
                );
            })
            .catch((error) => {
                console.error("Error publishing post:", error);
            });
    };

    const handleUnpublish = (postId) => {
        fetch(`https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/unpublish_post/${postId}`, { method: "PUT" }) // Update with unpublish endpoint
            .then(() => {
                console.log("Post unpublished successfully");
                setPosts(
                    posts.map(
                        (post) => (post._id === postId ? { ...post, pubblic: false } : post) // Use _id here
                    )
                );
            })
            .catch((error) => {
                console.error("Error unpublishing post:", error);
            });
    };

    const handleNextImage = (postId) => {
        setCurrentImageIndex((prev) => {
            const currentIndex = prev[postId] === undefined ? 0 : prev[postId];
            const post = posts.find((post) => post._id === postId);

            if (post && post.imageURLs) {
                const nextIndex = (currentIndex + 1) % post.imageURLs.length; // Use length of imageURLs
                return { ...prev, [postId]: nextIndex };
            }

            return prev; // Return previous state if post or imageURLs is undefined
        });
    };

    const handlePrevImage = (postId) => {
        setCurrentImageIndex((prev) => {
            const currentIndex = prev[postId] === undefined ? 0 : prev[postId];
            const post = posts.find((post) => post._id === postId);

            if (post && post.imageURLs) {
                const nextIndex =
                    (currentIndex === 0 ? post.imageURLs.length : currentIndex) - 1;
                return { ...prev, [postId]: nextIndex };
            }

            return prev; // Return previous state if post or imageURLs is undefined
        });
    };

    const handleEdit = (post) => {
        if (post) {
            setSelectedPost(post);
            handleShowUpdate(); // Show the UpdatePostModal
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem("user_id");
        navigate("/");
    };

    const openFullImgModal = (images, index) => {
        setCurrentImages(images);
        setCurrentImgIndex(index);
        setShowModal(true);
    };

    return (
        <div className="dashBoard_cont">
            <div className="nav_main">
                <div className="nav_main_container">
                    <p
                        className="annunci_disponibili"
                        onClick={() => setActiveSection("posts")}
                    >
                        <i className="fa-solid fa-sign-hanging"></i> Gestione annunci
                    </p>
                    <p
                        className="annunci_disponibili"
                        onClick={() => setActiveSection("contacts")}
                    >
                        <i className="fa-solid fa-users"></i> Gestione Contatti
                    </p>
                </div>
                <div className="nav_main_container">
                    <p className="logout_btn" onClick={handleLogout}>
                        <i className="fa-solid fa-right-from-bracket"></i> Esci
                    </p>
                </div>
            </div>
            <div className="main_cont">
                {activeSection === "posts" && (
                    <div className="manage_posts_cont">
                        <div className="function_bar">
                            <button className="add_post_btn" onClick={AddNewPostClick}>
                                <i className="fa-solid fa-plus"></i> Aggiungi annuncio
                            </button>
                            <p>{posts.length} annunci disponibili</p>
                        </div>
                        <div className="post_container">
                            {posts.map((post) => (
                                <div key={post._id} className="card_post">
                                    {" "}
                                    {/* Use _id here */}
                                    <div className="img_cont">
                                        <div className="scrol_btn_cont">
                                            <button
                                                className="scroll_button left"
                                                onClick={() => handlePrevImage(post._id)} // Use _id here
                                            >
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                            <button
                                                className="scroll_button right"
                                                onClick={() => handleNextImage(post._id)} // Use _id here
                                            >
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div className="img_wrapper">
                                            {post.imageURLs && post.imageURLs.length > 0 && (
                                                <div
                                                    className="img_shown"
                                                    style={{
                                                        backgroundImage: `url(${post.imageURLs[currentImageIndex[post._id]]
                                                            })`,
                                                    }}
                                                    onClick={() =>
                                                        openFullImgModal(
                                                            post.imageURLs,
                                                            currentImageIndex[post._id]
                                                        )
                                                    }
                                                ></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="info_cont">
                                        <div className="price_row">
                                            <div className="btn_cont_price">
                                                <button
                                                    className="price_btn_change"
                                                    onClick={() => handleEdit(post)}
                                                >
                                                    <i className="fa-solid fa-pencil"></i>
                                                </button>
                                                <button
                                                    className="price_btn_delete"
                                                    onClick={() => handleDelete(post._id, post.imageURLs)} // Use _id here
                                                >
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                                {post.pubblic ? (
                                                    <button
                                                        className="price_btn_unpublish"
                                                        onClick={() => handleUnpublish(post._id)} // Use _id here
                                                    >
                                                        <p>Rimuovi annuncio</p>
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="price_btn_publish"
                                                        onClick={() => handlePublish(post._id)} // Use _id here
                                                    >
                                                        <p>Pubblica annuncio</p>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="sell_rent">{post.status}</div>
                                            <div className="price">
                                                {Number(post.price).toLocaleString("it-IT")}€
                                            </div>
                                        </div>
                                        <div className="det_row">
                                            <div className="details_desc_title">
                                                {post.municipality} ({post.province}) - {post.mt2}m2
                                            </div>
                                            <div className="details_desc">{post.description}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {activeSection === "contacts" && <ContactsManager />}
            </div>
            <Footer />
            <AddPostModal
                show={addModalShow}
                handleClose={handleCloseAdd}
                editPost={false}
                existingPostData={selectedPost}
                onPostUpdated={fetchPosts}
            />
            <UpdatePostModal
                show={updateModalShow}
                handleClose={handleCloseUpdate}
                editPost={true}
                existingPostData={selectedPost}
                onPostUpdated={fetchPosts}
            />

            <FullImgModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                imageURLs={currentImages}
                currentImgIndex={currentImgIndex}
                setCurrentImgIndex={setCurrentImgIndex}
            />
        </div>
    );
}

export default Dashboard;
