// ToastConfirmation.js

import React from 'react';
import { Toast } from 'react-bootstrap';

const ToastConfirmation = ({ show, onClose, message, type }) => {
    return (
        <Toast show={show} onClose={onClose} style={{ position: 'absolute', top: '20px', right: '20px' }}>
            <Toast.Body className={type === "success" ? "text-success" : "text-danger"}>
                {message}
            </Toast.Body>
        </Toast>
    );
};

export default ToastConfirmation;
