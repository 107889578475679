import React, { useEffect, useState } from "react";
import { Table, Dropdown } from "react-bootstrap";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import ToastConfirmation from "./ToastConfirmation";

const ContactsManager = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState("success");

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await fetch("https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/get_all_contacts", {
                    method: "GET",
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch contacts");
                }
                const data = await response.json();
                setContacts(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchContacts();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const handleDeleteClick = (contactId) => {
        setSelectedContactId(contactId);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedContactId(null);
    };

    const handleDeleteContact = async () => {
        try {
            const response = await fetch("https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/delete_contacts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: selectedContactId }),
            });

            if (!response.ok) {
                throw new Error("Failed to delete contact");
            }

            setContacts(contacts.filter(contact => contact._id !== selectedContactId));
            handleCloseModal();
            setToastMessage("Contatto eliminato con successo!");
            setToastType("success");
        } catch (error) {
            console.error("Error deleting contact:", error);
            setToastMessage("Errore durante l'eliminazione del contatto.");
            setToastType("error");
        } finally {
            setShowToast(true);

            setTimeout(() => {
                handleToastClose();
            }, 4000);
        }
    };

    const handleToastClose = () => setShowToast(false);

    return (
        <div className="manage_contacts_cont">
            <Table className="table" striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>Email</th>
                        <th>Telefono</th>
                        <th>Messaggio</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.length > 0 ? (
                        contacts.map((contact) => (
                            <tr key={contact._id}>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                            <i className="fa-solid fa-ellipsis-vertical" style={{ color: 'black' }}></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item style={{ color: 'var(--danger)' }} onClick={() => handleDeleteClick(contact._id)}>
                                                <i className="fa-solid fa-trash"></i> Elimina contatto
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td>{contact.nome}</td>
                                <td>{contact.cognome}</td>
                                <td>{contact.email}</td>
                                <td>{contact.telefono}</td>
                                <td>{contact.messaggio}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">Nessun contatto disponibile</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <DeleteConfirmationModal
                show={showModal}
                onHide={handleCloseModal}
                handleDeleteContact={handleDeleteContact} />

            <ToastConfirmation 
                show={showToast} 
                onClose={handleToastClose}
                message={toastMessage}
                type={toastType} 
            />
        </div>
    );
};

export default ContactsManager;
