import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function FullImgModal({ show, handleClose, imageURLs, currentImgIndex, setCurrentImgIndex }) {
    
    const handleNext = () => {
        setCurrentImgIndex((prevIndex) => (prevIndex + 1) % imageURLs.length);
    };

    const handlePrev = () => {
        setCurrentImgIndex((prevIndex) =>
            prevIndex === 0 ? imageURLs.length - 1 : prevIndex - 1
        );
    };

    // Close modal on 'Esc' key press
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                handleClose();
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [handleClose]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-fullscreen"
        >
            <button className="close_img_btn" onClick={handleClose}>
                Chiudi
            </button>
            <Modal.Body className="p-0">
                <div className="img_body">
                    <div className='btn_cont'>
                        <button className="btn btn-dark" onClick={handlePrev}>
                            <i className="fa fa-chevron-left"></i>
                        </button>
                        <button className="btn btn-dark" onClick={handleNext}>
                            <i className="fa fa-chevron-right"></i>
                        </button>
                    </div>
                    <img
                        src={imageURLs[currentImgIndex]}
                        alt="Full screen"
                        className="img-fluid"
                        style={{ maxHeight: '90vh' }}
                    />

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default FullImgModal;
