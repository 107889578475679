// DeleteConfirmationModal.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteConfirmationModal = ({ show, onHide, handleDeleteContact }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <div className="modal_body">
                    <p className="confirm_modal_title">Eliminare il contatto selezionato?</p>
                    <p className="confirm_modal_mex">Una volta eliminato non sarà più possibile recuperare il contatto eliminato.</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annulla
                </Button>
                <Button variant="danger" onClick={() => { 
                    handleDeleteContact(); 
                    onHide(); // Close modal after deletion
                }}>
                    Elimina
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;
