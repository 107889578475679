import React from "react";

function Spinner({ loading, err }) {
    return (
        <div className="spinner_cont">
            {loading && (
                <div id="spinner" className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            )}
            {err.display && <p className="error_message" id="err">{err.message}</p>}
        </div>
    );
}

export default Spinner;
