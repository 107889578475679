import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import FilterPosts from "./FilterPosts";
import Footer from "../Footer";
import FullImgModal from './FullImgModal';

function ImmobiliInVendita() {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [filters, setFilters] = useState({
        tipologia: "",
        provincia: "",
        comune: "",
        venditaAffitto: "",
        prezzoMin: "",
        prezzoMax: "",
        mt2: "",
    });

    const [currentImageIndex, setCurrentImageIndex] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    useEffect(() => {
        fetch('https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/get_all_posts')
            .then((response) => response.json())
            .then((data) => {
                const postsArray = data.filter(post => post.pubblic && post.status === "Vendita");
                setPosts(postsArray);
                setFilteredPosts(postsArray);
            })
            .catch((error) => {
                console.error("Error fetching posts:", error);
            });
    }, []);

    useEffect(() => {
        const filtered = posts.filter(post => {
            return (
                (filters.tipologia === "" || post.type === filters.tipologia) &&
                (filters.provincia === "" || post.province === filters.provincia) &&
                (filters.comune === "" || post.municipality === filters.comune) &&
                (filters.venditaAffitto === "" || post.status === filters.venditaAffitto) &&
                (filters.prezzoMin === "" || post.price >= parseFloat(filters.prezzoMin)) &&
                (filters.prezzoMax === "" || post.price <= parseFloat(filters.prezzoMax)) &&
                (filters.mt2 === "" || post.mt2 <= parseFloat(filters.mt2))
            );
        });
        setFilteredPosts(filtered);
    }, [filters, posts]);

    const scroll = (postId, direction) => {
        setCurrentImageIndex(prevState => {
            const currentIdx = prevState[postId] || 0; // Get the current image index for the post
            const post = filteredPosts.find(p => p._id === postId); // Use _id instead of id
            const numImages = post.imageURLs ? post.imageURLs.length : 0;

            let newIndex = currentIdx;
            if (direction === 'left') {
                newIndex = currentIdx === 0 ? numImages - 1 : currentIdx - 1;
            } else {
                newIndex = currentIdx === numImages - 1 ? 0 : currentIdx + 1;
            }

            return { ...prevState, [postId]: newIndex };
        });
    };

    const openFullImgModal = (images, index) => {
        setCurrentImages(images);
        setCurrentImgIndex(index);
        setShowModal(true);
    };

    return (
        <div className="main_view">
            <Navbar />
            <div className="container_card">
                <FilterPosts filters={filters} setFilters={setFilters} />

                <div className="card_container">
                    {filteredPosts.length === 0 ? (
                        <p className="no_posts">Nessun annuncio disponibile</p>
                    ) : (
                        filteredPosts.map(post => {
                            const currentIdx = currentImageIndex[post._id] || 0; // Use _id instead of id

                            return (
                                <div key={post._id} className="card_post"> {/* Use _id instead of id */}
                                    <div className="img_cont">
                                        <div className='scrol_btn_cont'>
                                            <button
                                                className="scroll_button left"
                                                onClick={() => scroll(post._id, 'left')} // Use _id instead of id
                                            >
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                            <button
                                                className="scroll_button right"
                                                onClick={() => scroll(post._id, 'right')} // Use _id instead of id
                                            >
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div className="img_wrapper">
                                            {post.imageURLs && post.imageURLs.map((url, index) => (
                                                <div
                                                    key={index}
                                                    className={`img_shown ${index === currentIdx ? 'active' : ''}`}
                                                    style={{
                                                        backgroundImage: `url(${url})`,
                                                        display: index === currentIdx ? 'block' : 'none'
                                                    }}
                                                    onClick={() => openFullImgModal(post.imageURLs, currentIdx)} // Open modal on click
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="info_cont">
                                        <div className="price_row">
                                            <div className="sell_rent">
                                                <p>{post.status}</p>
                                            </div>
                                            <div className="price">
                                                {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(Number(post.price))}€
                                            </div>
                                        </div>
                                        <div className="det_row">
                                            <div className="details_desc_title">{post.type} - {post.mt2}mq</div>
                                            <div className="details_desc">
                                                {post.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>

            <Footer />

            <FullImgModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                imageURLs={currentImages}
                currentImgIndex={currentImgIndex}
                setCurrentImgIndex={setCurrentImgIndex}
            />
        </div>
    );
}

export default ImmobiliInVendita;
