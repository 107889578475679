// App.js
import React from 'react';

import Navbar from '../Navbar';
import Body from './Body';
import Footer from '../Footer';


function HomePage() {
  return (
    <div className='main_view'>
      <Navbar />
      <Body />
      <Footer />
    </div>
  );
}

export default HomePage;
