import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ContactsForm from "../Form/ContactsForm";

function Servizi() {
  const [page, setPage] = useState("");
  const servicesList = [
    "Redazione contratti preliminari di compravendita e contratti di affitto",
    "Valutazione gratuita/stima del tuo immobile",
    "Servizio di consulenza mutui",
    "Assistenza Tecnica Catastale",
    "Assistenza Notarile",
    "Stima dei lavori di ristrutturazione",
    "Visite di accompagnamento gratuite e senza impegno degli immobili scelti",
    "Certificati A.P.E. (Attestato di Prestazione Energetica) con tecnici abilitati convenzionati",
    "Inserimento del Vostro immobile sul Nostro sito internet e sui portali immobiliari più importanti",
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % servicesList.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [servicesList.length]);

  useEffect(() => {
    setPage("Servizi");
  }, []);

  return (
    <div className="main_view">
      <Navbar />

      <div className="services-content">
        <div className="services-container">
          {servicesList.map((service, index) => (
            <div
              key={index}
              className={`service-item ${index === activeIndex ? "active" : ""}`}
            >
              <div className="icon-text-container">
                <i className="fa-solid fa-circle-check"></i>
                <p>{service}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="form-container">
          <ContactsForm page={page} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Servizi;
