import React, { useState, useEffect } from "react";
import comuniData from "../../Json/gi_comuni.json"

function FilterPosts({ filters, setFilters, applyFilters }) {
    const [provinces, setProvinces] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);

    // Extract unique provinces from comuniData
    useEffect(() => {
        if (comuniData) {
            const uniqueProvinces = [...new Set(comuniData.map(comune => comune.sigla_provincia))];
            setProvinces(uniqueProvinces);
        }
    }, [comuniData]);

    // Filter municipalities based on the selected province
    useEffect(() => {
        if (comuniData && filters.provincia) {
            const filtered = comuniData
                .filter(comune => comune.sigla_provincia === filters.provincia)
                .map(comune => comune.denominazione_ita);
            setFilteredMunicipalities(filtered);
        } else {
            setFilteredMunicipalities([]);
        }
    }, [filters.provincia, comuniData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    return (
        <div className="filter_cont">
            <div className="filter_card">
                <p className="filter_title">Filtra i risultati</p>

                <p className="filter_labels">Tipologia</p>
                <select
                    name="tipologia"
                    className="filter_input"
                    value={filters.tipologia}
                    onChange={handleChange}
                >
                    <option value="">-</option>
                    <option value="Appartamento">Appartamento</option>
                    <option value="Attività commerciale">Attività commerciale</option>
                    <option value="Bifamiliare">Bifamiliare</option>
                    <option value="Casa Singola">Casa singola</option>
                    <option value="Garage">Garage</option>
                    <option value="Magazzino">Magazzino</option>
                    <option value="Negozio">Negozio</option>
                    <option value="Posto auto">Posto auto</option>
                    <option value="Rustico">Rustico</option>
                    <option value="Terreno">Terreno</option>
                    <option value="Ufficio">Ufficio</option>
                    <option value="Villa">Villa</option>
                </select>

                <p className="filter_labels">Provincia</p>
                <select
                    name="provincia"
                    className="filter_input"
                    value={filters.provincia}
                    onChange={handleChange}
                >
                    <option value="">-</option>
                    {provinces.map((prov, idx) => (
                        <option key={idx} value={prov}>{prov}</option>
                    ))}
                </select>

                <p className="filter_labels">Comune</p>
                {/* <select
                    name="comune"
                    className="filter_input"
                    value={filters.comune}
                    onChange={handleChange}
                >
                    <option value="">-</option>
                    {filteredMunicipalities.map((comune, idx) => (
                        <option key={idx} value={comune}>{comune}</option>
                    ))}
                </select> */}
                <input name="comune" className="filter_input" value={filters.comune} onChange={handleChange}></input>

                <p className="filter_labels">Vendita/Affitto</p>
                <select
                    name="venditaAffitto"
                    className="filter_input"
                    value={filters.venditaAffitto}
                    onChange={handleChange}
                >
                    <option value="">-</option>
                    <option value="Vendita">Vendita</option>
                    <option value="Affitto">Affitto</option>
                </select>

                <p className="filter_labels">Prezzo minimo</p>
                <input
                    name="prezzoMin"
                    className="filter_input"
                    placeholder="Min"
                    value={filters.prezzoMin}
                    onChange={handleChange}
                />

                <p className="filter_labels">Prezzo massimo</p>
                <input
                    name="prezzoMax"
                    className="filter_input"
                    placeholder="Max"
                    value={filters.prezzoMax}
                    onChange={handleChange}
                />

                <p className="filter_labels">Metri quadri</p>
                <input
                    name="mt2"
                    className="filter_input"
                    placeholder="nn"
                    value={filters.mt2}
                    onChange={handleChange}
                />

                <button
                    className="filter_btn"
                    onClick={applyFilters} // Apply filters when button is clicked
                >
                    Filter
                </button>
            </div>
        </div>
    );
}

export default FilterPosts;
