import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ContactsForm({ page }) {
    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        messaggio: '',
        section: '',
    });

    const [isChecked, setIsChecked] = useState(false); 
    const [checkboxError, setCheckboxError] = useState(false);
    const [loading, setLoading] = useState(false); // Added loading state

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            section: page,
        }));
    }, [page]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            setCheckboxError(false);
        }
    };

    const saveData = () => {
        setLoading(true); // Set loading to true before fetching
        fetch('https://stilnovoimmobiliare-a3d30bbe12c6.herokuapp.com/save_new_contacts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setLoading(false); // Reset loading to false after response
                if (data.message === "OK") {
                    alert('Messaggio inviato con successo!');
                    // Reset form data
                    setFormData({
                        nome: '',
                        cognome: '',
                        email: '',
                        telefono: '',
                        messaggio: '',
                        section: '',
                    });
                    setIsChecked(false);
                } else if (data.error) {
                    console.log("Error", data.error);
                }
            })
            .catch(error => {
                setLoading(false); // Reset loading in case of error
                console.error('Errore durante l\'invio del messaggio:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Sto cliccando");

        if (!isChecked) {
            setCheckboxError(true);
            return;
        }

        saveData(); // Call the new saveData function
    };

    return (
        <div className='main_form'>
            <p className='title_form'>Contattaci!</p>
            <form onSubmit={handleSubmit}>
                <div className='form-row'>
                    <input
                        type='text'
                        placeholder='Nome'
                        className='input'
                        name='nome'
                        value={formData.nome}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        placeholder='Cognome'
                        className='input'
                        name='cognome'
                        value={formData.cognome}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-row'>
                    <input
                        type='email'
                        placeholder='Email'
                        className='input'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        placeholder='Telefono'
                        className='input'
                        name='telefono'
                        value={formData.telefono}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-row'>
                    <textarea
                        placeholder='Lascia un messaggio...'
                        className='textarea'
                        name='messaggio'
                        value={formData.messaggio}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className='privacy_cont'>
                    <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label
                        style={{
                            color: checkboxError ? 'red' : 'inherit'
                        }}
                    >
                        Dichiaro di aver letto e compreso l'<Link to="/privacy_policy">Informativa sulla Privacy</Link> e acconsento al trattamento dei miei dati personali per le finalità indicate
                    </label>
                </div>
                <button type='submit' className='submit-button' disabled={loading}>
                    {loading ? 'Invio...' : 'Invia'} {/* Loading button text */}
                </button>
            </form>
        </div>
    );
}

export default ContactsForm;
