import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactsForm from '../Form/ContactsForm';

const Body = () => {
    const [page, setPage] = useState("");
    const [formBorder, setFormBorder] = useState('1px solid #ccc');
    const formRef = useRef(null);

    const handleScrollToForm = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
        setFormBorder('1px solid var(--mainColor)');

        setTimeout(() => {
            setFormBorder('1px solid var(--lightGray)');
        }, 5000);
    };

    // Use useEffect to set the page state when the component mounts
    useEffect(() => {
        setPage("Home-Page");
    }, []); // The empty dependency array ensures this runs only on mount

    return (
        <div className='body'>
            <div className='section section_image'>
                <div className='img_back'></div>
                <div className='punchline_phrase'>
                    <p>Trova la tua casa ideale...</p>
                    <Link to="/immobili-in-vendita"><button className='more_btn'>Scopri di più</button></Link>
                </div>
            </div>

            <div className='section section_row'>
                <div className='box box1'>
                    <p className='box_title'>Valuta il tuo immobile!</p>
                    <div className='img_box img_1'></div>
                    <p className='main_cont_box'>Richiedi una valutazione del tuo immobile in pochi passaggi!</p>
                    <button className='box_btn' onClick={handleScrollToForm}>Valuta ora!</button>
                </div>
                <div className='box box2'>
                    <p className='box_title'>Trova la casa che fa per te!</p>
                    <div className='img_box img_2'></div>
                    <p className='main_cont_box'>Scopri la tua casa ideale: città, campagna o mare. Troviamo insieme il tuo rifugio perfetto!</p>
                    <Link to="/immobili-in-vendita"><button className='box_btn'>Trova la casa!</button></Link>
                </div>
            </div>
            <div className='section section_form' ref={formRef} style={{ border: formBorder }}>
                <ContactsForm page={page} />
            </div>
        </div>
    );
};

export default Body;
