// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";

import HomePage from './components/Homepage/HomePage';
import ImmobiliInVendita from './components/Immobili_in_vendita/ImmobiliInVendita';
import Servizi from './components/Website/Servizi';
import Agency from './components/Website/Agency';
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import PrivacyPolicy from './components/Website/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className='main_view'>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/immobili-in-vendita" element={<ImmobiliInVendita />} />
          <Route path="/servizi" element={<Servizi />} />
          <Route path="/agenzia" element={<Agency />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
